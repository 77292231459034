@import './_variables.scss';

.paginationContainer {
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 40px 0;
    overflow-x: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /*firefox*/
    /*everything else*/
    &::-webkit-scrollbar {
        display: none;
    }
    .pagination {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;

        .paginationButton {
            background: var(--color-fff-000);
            border: 2px solid var(--color-388713-419d16);
            width: 100%;
            max-width: 42px;
            border-radius: 6px;
            margin: 0 4px;
            box-sizing: border-box;
            cursor: pointer;

            a {
                justify-content: center;
                padding: 11px 16px;
                display: flex;
            }

            .paginationNumber {
                font-family: 'Roboto Condensed';
                font-style: normal;
                font-weight: 700;
                font-size: 18px;
                line-height: 18px;
                height: 100%;
                color: var(--color-388713-419d16);
                height: 100%;
                width: 100%;
            }
        }
        .paginationButton.curPage {
            background: var(--color-388713-419d16);
            .paginationNumber {
                color: var(--color-fff-1a1a1a);
            }
        }

        .paginationDots {
            font-family: 'Roboto Condensed';
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 18px;
            display: flex;
            align-items: center;
            text-align: center;
            color: var(--color-388713-419d16);
            margin: 0 4px;
        }
        .paginationChevron {
            margin: 0 2px;
            max-width: 42px;
            border-radius: 6px;
            cursor: pointer;
            a {
                justify-content: center;
                padding: 11px 15px;
                display: flex;
            }
        }
    }
}

@media only screen and (max-width: 580px) {
    .paginationContainer {
        .pagination {
            width: 100%;
        }
        .paginationFlexStart {
            justify-content: flex-start;
        }
    }
}
