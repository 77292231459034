@import '_variables.scss';

.wrapper {
    margin: 15px 0 15px 0;
    padding: 0;
}

.container {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;

    overflow: scroll;
    white-space: nowrap;
    scrollbar-width: none;
    -ms-overflow-style: none;
    -webkit-overflow-scrolling: touch;

    .tag {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        white-space: nowrap;
        border-radius: 4px;
        padding: 5px;
        color: var(--color-2f72eb-4b85ee);
        border: 1px solid var(--color-2f72eb-4b85ee);
        .numberOfSites {
            background-color: var(--color-d1ecf1-292929);
            margin-left: 10px;
            padding: 0 5px;
            font-family: Arial;
            color: var(--color-2f72eb-4b85ee);
        }
    }
}

@media only screen and (max-width: $tabletBreakpoint) {
    .container {
        flex-flow: row;
        justify-content: flex-start;
    }
}

@media only screen and (min-width: $tabletBreakpoint) {
    .container {
        overflow: hidden;
    }
}
