.filterList {
    display: flex;
    flex-flow: row nowrap;
    height: 42px;
    justify-content: flex-start;
    align-items: center;
    overflow-x: auto;
    margin: 24px 0 0 0;

    & > :first-child {
        gap: 0;
    }
}

.filteredList {
    display: flex;
    flex-direction: row;
    gap: 6px;
    align-items: center;
}

.bubbleTag {
    height: 42px;
    padding: 9px 12px;
    border: 2px solid var(--color-004cd5-2F72EB);
    border-radius: 4px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 3px;
    margin-right: 6px;
    text-align: center;
    color: var(--color-004cd5-2F72EB);
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    text-transform: capitalize;
    line-height: 24px;
    white-space: nowrap;
    cursor: pointer;

    &.active {
        background: var(--color-004cd5-2F72EB);
        color: var(--color-fff-000);
    }

    .bubbleTagContent {
        text-align: center;
        color: var(--color-004cd5-2F72EB);
        font-size: 16px;
        font-family: 'Roboto', sans-serif;
        font-weight: 700;
        text-transform: capitalize;
        line-height: 24px;
        word-wrap: break-word;
    }
}

.clearFilters {
    width: 30px;
    height: 30px;
    padding: 3px;
    border-radius: 100%;
    overflow: hidden;
    border: 2px solid var(--color-292929-a3a3a3);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-right: 6px;

    button {
        width: 24px;
        height: 24px;
        position: relative;
        background: none;

        > div {
            background: none;
            width: 12px;
            height: 12px;
            position: absolute;
            left: 6px;
            top: 6px;
            border: 2px solid var(--color-292929-a3a3a3);
        }
    }
}
