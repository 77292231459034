@import '../../common-scss/_variables.scss';

.center {
    text-align: center;
    padding-top: 20px;
    position: relative;

    .line {
        border: 2px solid #178706;
        width: 40px;
        border-radius: 4px;
        margin: 0 auto;
        margin-top: 8px;
    }
}
.onlyfans {
    padding-top: 20px;
    margin-bottom: -10px;
}

.wrapper.review h2 {
    display: flex;
    justify-content: center;
}

.wrapper.review {
    text-align: center;
    padding: 20px 0;
    position: relative;
    margin: 0 0 40px 0;
    border-radius: 6px;
    border: 2px solid var(--color-f5f5f5-00);
    background: var(--color-f5f5f5-292929);

    /* Elevation - Header */
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.15);
    h2 {
        font-size: 24px;
    }
    .line {
        border: 2px solid #178706;
        width: 40px;
        border-radius: 4px;
        margin: 8px auto 18px auto;
    }
    .container {
        .row {
            .left {
                padding: 0 0 0 0px;
            }
            .default {
                padding: 0 5px 0 5px;
            }
        }
    }
}

.wrapper.homepage-linkout {
    .container {
        .row {
            .left {
                padding: 0 0 0 0px;
            }
            .default {
                padding: 0 15px 0 15px;
            }
        }
    }
}
.wrapper.subtabs {
    margin: 30px 0 0 0;
}

.wrapper {
    width: 100%;
    margin: 0 auto;

    h2 {
        display: flex;
        align-items: center;

        color: var(--color-000-fff);
        font-family: Roboto Condensed;
        font-size: 22px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: capitalize;
        margin-left: 15px;

        svg {
            stroke: var(--color-000-fff);
        }

        a {
            display: flex;
            align-items: center;
            color: var(--color-000-fff);
        }
    }

    .overflow {
        width: 100vw; // Carousel full width
    }

    .container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        overflow: hidden;

        .row {
            display: flex;
            align-items: center;

            width: 100%;
            overflow: hidden;

            .left {
                padding: 0 0 0 36px;
            }

            .default {
                padding: 0 0 0 15px;
            }

            .transform {
                display: flex;
                //margin: 0 15px;

                width: inherit;
                overflow-x: scroll; /* Hide horizontal scrollbar */
                -ms-overflow-style: none; /* IE and Edge */
                scrollbar-width: none; /* Firefox */
                &::-webkit-scrollbar {
                    display: none;
                }

                .default {
                    gap: 15px;
                }
                .small {
                    gap: 6px;
                }
                .list.onlyfansList {
                    padding: 15px 0;
                }
                .list.partnervideos {
                    gap: 15px;
                }
                .list {
                    display: flex;
                    flex-flow: row;
                    list-style: none;
                    padding: 0;
                    margin: 0;

                    li {
                        margin: 0;
                        width: 100%;
                        cursor: pointer;
                        position: relative;
                        background-color: #4d4d4d;
                        border-radius: 6px;
                        margin: 15px 0; // full shadow fix

                        .card {
                            border-radius: 6px; // match li
                            box-shadow: 0px 0px 6.75px 2.25px rgba(0, 0, 0, 0.15);
                            width: 130px;
                            height: 120px;

                            .image {
                                border-radius: 6px;
                            }

                            p {
                                display: flex;
                                flex-direction: column;
                                justify-content: center;
                                position: absolute;
                                padding: 0 5px;
                                background: rgba(0, 0, 0, 0.14);

                                width: 100%;
                                height: 100%;
                                overflow: hidden;
                                color: #fff;

                                text-overflow: ellipsis;
                                text-align: center;
                                text-shadow: 0px 0px 10px #000;
                                font-family: Roboto Condensed;
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 700;
                                line-height: normal;

                                z-index: 10;
                            }
                        }
                    }
                }
            }
        }

        .navigation {
            display: none;
        }
    }

    button {
        cursor: pointer;
        height: 40px;
        width: 40px;
        color: var(--color-000-fff);

        border-radius: 30px;
        background-color: transparent;

        &:disabled {
            display: none;
            transition: background-color 0.3s ease;
        }
    }
}

// Carousel full width
@media only screen and (max-width: $desktopBreakpoint) {
    .wrapper.homepage-linkout {
        margin: 20px 0 40px 0;
    }
}

@media only screen and (min-width: $mobileBreakpoint) {
    .wrapper {
        .container {
            // For Review Carousel
            .tablet {
                display: flex;
                align-items: center;
                width: 100%;
            }
            // For Default Carousel
            .navigation {
                margin-bottom: 15px;
                svg {
                    stroke: var(--color-000-fff);
                }
            }

            .start {
                justify-content: space-between;
            }
            .end {
                justify-content: flex-end;
            }
            .default {
                justify-content: space-between;
            }
        }
        .overflow {
            width: auto;
        }
    }
}
@media only screen and (max-width: $desktopBreakpoint) and (min-width: 1000px) {
    .onlyfans {
        max-width: 1000px;
        margin: auto;
    }
}

@media only screen and (min-width: $desktopBreakpoint) {
    .wrapper {
        h2 {
            font-size: 26px;
            margin-left: 15px;
        }

        .container {
            .row .transform .list li .card {
                width: 170px;
                height: 160px;
            }
            // For Default Carousel
            .navigation {
                display: flex;
                align-items: center;
                width: 100%;

                margin-bottom: 5px;
            }
        }
    }
}
