@import '../../common-scss/_variables.scss';

.container {
    display: flex;
    flex-direction: column;
    width: 100%;

    border: 1px solid #ccc;
    background: var(--color-fff-121212);
    border-radius: 6px;
    margin-bottom: 15px;

    @media only screen and (min-width: 441px) and (max-width: 550px) {
        min-width: 225px;
        max-width: 235px;
    }
    @media only screen and (min-width: 551px) and (max-width: 764px) {
        min-width: 225px;
        max-width: 305px;
    }
    @media only screen and (min-width: 765px) and (max-width: 1100px) {
        width: 100%;
        min-width: 281px;
        max-width: 305px;
    }
    @media only screen and (min-width: 1101px) {
        width: 100%;
        min-width: 281px;
        max-width: 345px;
    }
    @media only screen and (min-width: $largeDesktopBreakpoint) {
        min-width: 281px;
        max-width: 355px;
    }

    &:hover {
        -webkit-filter: drop-shadow(0 0 3px var(--color-216600-419d16));
        filter: drop-shadow(0 0 3px var(--color-216600-419d16));
    }

    .head {
        .image {
            margin-bottom: 10px;
            position: relative;
            border-radius: 6px 6px 0 0;
            height: 220px;
            width: 100%;
            position: relative;

            .right {
                display: flex;
                align-items: flex-end;
                justify-content: center;
                position: absolute;
                padding: 3px 6px;
                width: 70px;

                bottom: 0px;
                right: 0;
                width: 100px;
                overflow: hidden;

                text-transform: capitalize;
                text-overflow: ellipsis;
                -o-text-overflow: ellipsis;

                background: #000;
                color: #fff;
                text-decoration: none;

                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 150%; /* 24px */

                z-index: 1;
                cursor: default;
            }

            .label {
                font-family: 'Roboto Condensed', sans-serif;
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;

                padding: 5px 8px;
                bottom: 6px;
                left: 6px;

                background: var(--color-216600-419d16);
                border-radius: 3px;
                color: #fff;
                z-index: 1;

                font-size: 14px;
                text-align: center;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                text-transform: uppercase;
                cursor: default;
            }
        }
    }

    .content {
        display: flex;
        flex-direction: column;
        gap: 6px;
        padding: 12px;
        width: 100%;
        max-width: 330px;
        cursor: default;

        .text {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 12px;
            @include text-ellipsis;

            h3 {
                @include text-ellipsis;
            }

            .rating {
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 150%; /* 24px */
                color: var(--color-4a4a4a-fff);
            }
        }

        .features {
            display: inline-block;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            text-align: left;

            a {
                cursor: pointer;
            }

            .inactive {
                color: var(--color-000-f5f);
                text-decoration-line: none;
            }

            span {
                .active {
                    color: var(--color-216600-4b85ee);
                    cursor: pointer;
                }

                margin: 0 9px 0 0;

                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 21px;
                text-decoration-line: underline;

                word-break: break-all;
            }
        }

        .corner {
            justify-content: space-between;
        }

        .title {
            display: block;
            max-width: fit-content;

            color: var(--color-000-f5f);
            text-decoration: none;

            overflow: hidden;
            text-overflow: ellipsis;

            font-family: Roboto Condensed;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-transform: capitalize;
        }

        .subtitle {
            overflow: hidden;
            color: var(--color-000-f5f);
            text-overflow: ellipsis;
            font-family: Roboto;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 150%; /* 24px */
            text-decoration-line: underline;
            a {
                color: var(--color-000-f5f);
            }
        }

        .date {
            text-align: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%; /* 24px */
        }
        .white {
            color: var(--color-4a4a4a-fff);
        }
        .green {
            color: var(--color-216600-419d16);
        }

        p {
            overflow: hidden;
            color: var(--color-216600-4b85ee);
            text-overflow: ellipsis;
            text-decoration-line: underline;

            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%; /* 21px */
        }
        .description {
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            font-family: Arial;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            color: var(--color-000-f5f);
        }
    }
}

@media only screen and (min-width: $tabletBreakpointMax) {
    .container {
        .content {
            width: 100%;
            max-width: 100%;
        }
    }
}

@media only screen and (min-width: $tabletBreakpoint) {
    .container {
        .content {
            width: 100%;
            max-width: 100%;
        }
    }
}
