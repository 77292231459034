@import '../common-scss/_variables.scss';
.home {
    display: inline-flex;
    margin-left: 15px;
    margin-top: 15px;
    margin-bottom: 15px;
    justify-content: flex-start;
    align-items: flex-start;
}
.categories {
    display: flex;
    justify-content: center;
    align-items: center;

    margin: 24px 2px 0;
}
.deals {
    display: inline-flex;
    margin: 0 0 0 0;
    justify-content: center;
    align-items: center;
}
.tabContainer {
    .tab {
        padding: 9px 12px;
        display: flex;
        justify-content: center;
        align-items: center;

        font-family: 'Roboto Condensed';
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: uppercase;

        word-wrap: break-word;
        cursor: pointer;
        transition: all 0.3s ease;
        height: 37px;

        color: var(--color-388713-419d16);
        background-color: var(--color-fff-000);
        outline: 2px solid var(--color-388713-419d16);
        box-shadow: 2px 3px 4px 0.5px rgba(0, 0, 0, 0.3);

        &.activeTab {
            background: var(--color-388713-419d16);
            color: var(--color-fff-000);
            box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
            border: none;
        }

        &:not(.activeTab) {
            box-shadow: 2px 2px 4px 3px rgba(0, 0, 0, 0.1);
        }

        &:first-child {
            border-top-left-radius: 6px;
            border-bottom-left-radius: 6px;
        }

        &:last-child {
            border-top-right-radius: 6px;
            border-bottom-right-radius: 6px;
        }
    }
}

@media (min-width: $mobileBreakpoint) {
    .home {
        margin-top: 25px;
        justify-content: flex-start;
        margin: 15px 2.3vw 15px;
    }
    .deals {
        margin: 0 0 0 0;
    }

    .categories {
        margin-top: 24px;
        justify-content: flex-start;
        margin: 24px 1.2vw 0;
    }
    .tabContainer {
        .tab {
            font-family: 'Roboto Condensed';
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-transform: uppercase;
            text-align: center;

            height: 47px;

            padding: 12px 15px;
            justify-content: center;
            align-items: center;
            gap: 3px;
        }
    }
}

@media (min-width: 1000px) {
    .categories {
        margin: 24px 2.2vw 0;
    }
    .home {
        margin: 15px 1.3vw 15px;
    }
    .deals {
        margin: 0 0 0 0;
    }
}

@media (min-width: 1280px) and (max-width: $largeDesktopBreakpoint) {
    .categories {
        margin: 24px 2px 0;
    }
    .home {
        margin: 15px 0.9vw 15px;
    }
    .deals {
        margin: 0 0 0 0;
    }
    .tabContainer {
        max-width: 1000px;
    }
}

@media (min-width: $largeDesktopBreakpoint) {
    .categories {
        margin: 24px 2px 0;
    }
    .home {
        margin: 15px 0.9vw 15px;
    }
    .deals {
        margin: 0 0 0 0;
    }
}
