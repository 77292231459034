@import '../../common-scss/_variables.scss';

.carouselWrapper {
    display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */

    margin: 0 15px 20px 15px;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    gap: 15px;

    .carouselContainer {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 12px;

        .row {
            display: flex;
            gap: 10px;
            align-items: center;
            justify-content: center;

            .thumbnail {
                position: relative;
                width: 100%;

                a span {
                    max-height: 100px;
                }

                .promoImage {
                    width: 100dvh;
                }
            }

            .arrow {
                display: none;

                svg {
                    stroke: var(--color-292929-ECECEC);
                }
            }

            button {
                display: flex;
                width: 36px;
                height: 36px;
                padding: 6px;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 10px;
                border-radius: 100px;
                border: 2px solid var(--color-292929-ECECEC);
                background: transparent;
                cursor: pointer;
                opacity: 1;
                transition: opacity 0.35s;
            }

            .inactive {
                opacity: 0;
                transition: opacity 0.35s;
            }
        }

        .carouselControls {
            margin: 0 auto;

            .carouselDot {
                cursor: pointer;
                width: 12px;
                height: 12px;
                margin: 0 3px;
                border-radius: 50%;
                background: var(--color-CCC-7A7A7A);
                border: none;
                padding: 0;
            }
            .active {
                background: var(--color-4d4d4d-ECECEC);
            }
        }
    }

    .heading,
    .headingOnly {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        justify-content: flex-start;
        width: 45%;
        align-self: flex-start;

        .title {
            font-family: 'Roboto Condensed';
            font-style: normal;
            font-weight: bold;
            font-size: 30px;
            line-height: 35px;
            color: var(--color-4d4d4d-fff);
            padding: 0;
            margin-top: 0;
            text-align: center;
        }

        hr {
            width: 12%;
            height: 3px;
            border: none;
            background: #f8bf00;
            margin: 5px 0;
        }
        .description {
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            margin-bottom: 5px;
            font-size: 16px;
            line-height: 24px;
            color: var(--color-4d4d4d-fff);
        }
        .readMoreSection {
            text-align: right;
            width: 100%;

            .readMoreButton {
                font-family: 'Roboto';
                font-style: normal;
                font-size: 16px;
                line-height: 16px;
                color: var(--color-388713-419d16);
                margin-right: 2px;
            }
        }
    }

    .headingOnly {
        margin-top: 30px;
        width: 100%;
        .title {
            margin-bottom: 15px;
        }
    }
}

@media only screen and (max-width: $desktopBreakpoint) {
    .carouselWrapper {
        flex-flow: column nowrap;
        background-color: var(--color-fff-000);

        .heading,
        .headingOnly {
            -webkit-box-flex: 1; /* OLD - iOS 6-, Safari 3.1-6 */
            -moz-box-flex: 1; /* OLD - Firefox 19- */
            -webkit-flex: 1; /* Chrome */
            -ms-flex: 1; /* IE 10 */
            flex: 1; /* NEW, */
            width: 100%;

            hr {
                width: 8%;
                margin: 15px 0;
            }
        }
        .headingOnly {
            margin-top: 15px;
        }
    }
}

@media only screen and (min-width: $mobileBreakpoint) and (max-width: 550px) {
    .carouselWrapper .carouselContainer .row {
        .thumbnail {
            max-width: 410px;

            a span {
                min-height: 70%;
                max-height: 110px;
                max-width: 440px;

                img {
                    min-height: 70%;
                }
            }
        }
    }
}
@media only screen and (min-width: 551px) and (max-width: 750px) {
    .carouselWrapper .carouselContainer .row {
        .thumbnail {
            max-width: 728px;
            margin: 0 auto;
            max-width: 500px;

            a span {
                min-height: 70%;
                max-height: 140px;

                img {
                    min-height: 70%;
                }
            }
        }
    }
}

@media only screen and (min-width: 750px) and (max-width: $desktopBreakpoint) {
    .carouselWrapper .carouselContainer .row {
        .thumbnail {
            max-width: 728px;
            margin: 0 auto;

            a span {
                min-height: 70%;
                max-height: 200px;

                img {
                    min-height: 70%;
                }
            }
        }
    }
}

//mobile only CSS
@media only screen and (max-width: $tabletBreakpoint) {
    .carouselWrapper {
        .heading,
        .headingOnly {
            padding: 0 5px;
            .description {
                -webkit-line-clamp: 3;
            }
        }
    }
}

@media screen and (min-width: 1280px) {
    .carouselWrapper {
        column-gap: 30px;
        height: 240px;
        flex-direction: row;
        flex-wrap: nowrap;

        .carouselContainer .row {
            display: grid;
            grid-template-columns: auto 1fr auto;
            grid-template-rows: 1fr;

            .thumbnail {
                max-width: 800px;
                margin: 0 auto;
                min-height: 70%;

                a span {
                    max-height: 170px;
                    min-width: 550px;
                }
            }

            .arrow {
                display: flex;
                flex-direction: row;
                align-items: center;
                width: 36px;
            }
        }

        .heading {
            height: 100%;
            width: 45%;
            justify-content: center;
        }
    }
}

@media only screen and (min-width: 1500px) {
    .carouselWrapper {
        .carouselContainer .row {
            .thumbnail {
                a span {
                    min-height: 70%;
                    max-height: 190px;
                    min-width: 550px;
                }
            }
        }
    }
}

@media only screen and (min-width: 1920px) {
    .carouselWrapper .carouselContainer .row {
        .thumbnail a span {
            min-height: 70%;
            max-height: 195px;
            max-width: 800px;
        }
    }
}
