@import '_variables.scss';

.container {
    height: auto;
    min-height: 1200px;
    max-width: 800px;
    //min-width: 600px;
    padding: 40px;
    border-radius: 6px;
    outline: 1px var(--color-a3a3a3-4d4d4d) solid;
    outline-offset: -1px;
    display: inline-flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;

    margin: 0 15px;
}

.title {
    align-self: stretch;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: var(--color-000-fff);
    font-size: 22px;
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 700;
    text-transform: capitalize;
    word-wrap: break-word;
    margin: 0;
}

.line {
    border: 2px solid var(--color-388713-419d16);
    width: 40px;
    border-radius: 4px;
    margin: 0 auto;
    margin-top: 12px;
}

.image {
    width: 100%;
    //height: 100%;
    max-width: 480px;
    min-height: 200px;
    max-height: 270px;

    overflow: hidden;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;

    margin-top: 24px;
    margin-bottom: 24px;
    margin-left: auto;
    margin-right: auto;

    img,
    .video {
        width: 100%;
        height: 100%;
        object-fit: cover;
        // object-fit: contain;
        // object-position: center;
    }

    .video {
        width: 100%;
        height: 100%;
        background: #000;
    }
}

.placeholder {
    display: flex;
    gap: 10px;
    align-items: center;
}

.placeholderItem {
    outline: 5px #7a7a7a solid;
    outline-offset: -2.5px;

    &.large {
        width: 45px;
        height: 45px;
    }

    &.small {
        width: 7.5px;
        height: 7.5px;
    }

    &.medium {
        width: 40px;
        height: 27.5px;
    }
}

.content {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 24px;
    transition: max-height 0.3s ease-in-out;

    &.clamp {
        max-height: 960px;
        overflow: hidden;
    }
}

.mainContent {
    width: 100%;
    color: var(--color-000-f5f);
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    line-height: 24px;
    white-space: pre-line;
}

.listContainer {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
}

.readMore {
    padding: 9px 12px;
    background: transparent;
    border-radius: 4px;
    outline: 2px var(--color-004cd5-2F72EB) solid;
    outline-offset: -2px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 3px;
    color: var(--color-004cd5-2F72EB);
    font-size: 16px;
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    cursor: pointer;
    border: none;
    margin: 12px 0;

    svg {
        transition: transform 0.3s ease;
    }
}

.newsItem {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 12px;
}

.number {
    width: 40px;
    height: 40px;
    padding: 6px;
    background: transparent;
    overflow: hidden;
    border-radius: 100px;
    // outline: 1px var(--color-216600-419d16) solid;
    // outline-offset: -1px;
    border: 1px solid var(--color-216600-419d16);

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;

    span {
        text-align: center;
        color: var(--color-000-fff);
        font-size: 21px;
        font-family: 'Roboto Condensed', sans-serif;
        font-weight: 700;
        text-transform: capitalize;
    }
}

.itemContent {
    flex: 1;
    min-width: 0;
}

.text {
    display: block;
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    line-height: 24px;
    word-wrap: break-word;
}

.itemTitle {
    color: var(--color-004cd5-2F72EB);
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}

.separator {
    color: var(--color-000-fff);
}

.description {
    color: var(--color-000-f5f);
    display: inline;
    word-wrap: break-word;
    overflow-wrap: break-word;
}

.gridContainer {
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr;
    gap: 40px;
    justify-items: center;

    margin-bottom: 20px;

    @media (min-width: $desktopBreakpoint) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
        margin-bottom: 40px;

        > div {
            display: flex;
            justify-content: flex-start;
        }
    }

    @media (min-width: $largeDesktopBreakpoint) {
        margin-bottom: 80px;
    }
}

@media only screen and (min-width: $tabletBreakpointMax) {
    .container {
        margin: 0 20px;
    }
}

@media only screen and (min-width: $desktopBreakpoint) {
    .container {
        margin: 0;
    }
}
