@import '../../common-scss/_variables.scss';

.container {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 0;
    width: 220px;
    margin: 15px 0;

    @media only screen and (min-width: 375px) and (max-width: 550px) {
        min-width: 260px;
        max-width: 280px;
    }

    .thumbnail {
        height: 144px;

        @media only screen and (max-width: 251px) {
            height: 122px;
        }
        .image {
            margin-bottom: 10px;
            position: relative;
            width: 100%;
            height: 100%;
        }
    }

    .content {
        display: flex;
        align-items: center;

        padding: 9px 9px 9px 0;
        width: 100%;
        max-width: 330px;
        gap: 10px;

        h3 {
            @include text-ellipsis;

            .title {
                color: var(--color-292929-f5f5f5);
                text-align: center;

                /* Mobile/SEO Box Header */
                font-family: 'Roboto Condensed';
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                text-transform: capitalize;
            }
        }
    }
}

@media only screen and (min-width: $tabletBreakpointMax) {
    .container {
        .content {
            width: 100%;
            max-width: 100%;
        }
    }
}

@media only screen and (min-width: $tabletBreakpoint) {
    .container {
        .content {
            width: 100%;
            max-width: 100%;
        }
    }
}
