@import '../../common-scss/_variables.scss';

.onlyfansCard {
    height: 100%;
    width: 100%;
    cursor: pointer;
    margin-top: 5px;
    .container {
        display: flex;
        flex-direction: column;
        width: 100%;
        width: 142px;

        gap: 0;
        border: 1px solid var(--color-cccccc-4d4d4d);
        border-radius: 6px;

        .thumbnail {
            height: 120px;
            width: 100%;
            position: relative;
            .ofLogo {
                position: absolute;
                top: 3%;
                right: 3%;
                z-index: 1;
            }
            @media only screen and (max-width: 251px) {
                height: 122px;
            }
            .image {
                position: relative;
                width: 100%;
                height: 100%;
                border-radius: 5px 5px 0 0;
            }
        }

        .content {
            display: flex;
            align-items: center;
            padding: 7px 5px;

            svg {
                fill: var(--color-000-fff);
            }

            .title {
                @include text-ellipsis;

                color: var(--color-292929-f5f5f5);
                font-family: 'Roboto Condensed';
                font-size: 18px;
                font-style: bold;
                font-weight: 700;
                line-height: 24px;
            }
            svg {
                margin-right: 0.75px;
            }
        }
    }
}

@media only screen and (min-width: $tabletBreakpointMax) {
    .container {
        .content {
            width: 100%;
            max-width: 100%;
        }
    }
}

@media only screen and (min-width: $tabletBreakpoint) {
    .container {
        .content {
            width: 100%;
            max-width: 100%;
        }
    }
}
